import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import CustomHelmet from "../components/custom-helmet"

// TODO: Sharing, mobile optimization etc

import "./project.scss"

interface Props {
  data: any
}

export default function Project(props: Props) {
  const project = props.data.contentfulProject

  if (!project) return null

  return (
    <>
      <CustomHelmet
        title={project.title}
        description={project.description.description}
      />

      <Header theme="black" />
      <div className="project">
        <div className="project__content">
          <div className="project__left-column">
            <h1 className="project__title">{project.title}</h1>
            <>
              <h2 className="project__subtitle">About the project</h2>
              <div className="project__about">
                {project.description.description}
              </div>
              <h2 className="project__subtitle">Technologies</h2>
              <div className="project__techologies">{project.technologies}</div>
              <h2 className="project__subtitle">Visit site</h2>
              <div className="project__visit-site">
                <a href={project.url} target="_blank" rel="noreferrer">
                  {project.url.replace("https://", "")}
                </a>
              </div>
            </>
          </div>
          <div className="project__right-column">
            {project.images &&
              project.images.map(image => {
                return <img src={image.file.url} />
              })}
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export const projectQuery = graphql`
  query ProjectQuery($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      title
      description {
        description
      }
      technologies
      url
      thumbnail {
        file {
          url
        }
      }
      images {
        file {
          url
        }
      }
    }
  }
`
